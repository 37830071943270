<template>
    <BT-Blade-Item
        bladeName="customer-order-schedule"
        :bladesData="bladesData"
        navigation="customer-order-schedules"
        title="Process Schedule">
        <template v-slot="d">
            
            <BT-Field-String
                v-model="d.item.scheduleName"
                label="Name"
                :isEditing="d.data.isEditing || d.data.isNew" />

            <BT-Field-Select
                v-model="d.item.supplyAgreementGroupID"
                navigation="customer-groups"
                label="Customer Group  (*Selecting no group means the schedule applies to ALL CUSTOMERS)"
                itemText="groupName"
                :isEditing="d.data.isEditing || d.data.isNew" />

            <BT-Field-Switch
                v-model="d.item.isOn"
                falseText="Off"
                :isEditing="d.data.isEditing || d.data.isNew"
                label="Status"
                trueText="On" />

            <BT-Field-Date
                v-if="d.item.isOn"
                v-model="d.item.nextAttentionDate"
                label="Next Occurrence"
                shortDateAndTime />

            <BT-Cron
                v-model="d.item.cronExpression"
                :isEditing="d.data.isEditing || d.data.isNew"
                label="Process Regularity"
                leadTimeInHours
                leadTimeLeftLabel="Everything Due After (>=) Hours After Process Time"
                leadTimeRightLabel="Everything Due Before (<) Hours After Process Time"
                editExpression
                showExpression />

            <BT-Cron-Adjustments
                v-model="d.item.adjustments"
                :cronExpression="d.item.cronExpression"
                :isEditing="d.data.isEditing || d.data.isNew"
                label="Adjustments"
                leadTimeInHours
                leadTimeLeftLabel="Everything Due After (>=) Hours After Process Time"
                leadTimeRightLabel="Everything Due Before (<) Hours After Process Time" />

        </template>

    </BT-Blade-Item>
</template>

<script>
export default {
    name: 'Customer-Order-Schedule-Blade',
    props: {
        bladesData: null
    }
}
</script>